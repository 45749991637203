import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const Footer = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 1,
    }}
  >
    <Typography
      variant="subtitle1"
      align="center"
      color="textSecondary"
      component="p"
      sx={{ lineHeight: '1.3rem', fontSize: '0.7rem', color: '#acacac' }}
    >
      Powered by{' '}
      <Link
        href="https://thrivemetrics.com/in-store-catalog/"
        target="_blank"
        color="inherit"
        sx={{ textDecoration: 'none' }}
      >
        thrivemetrics.com
      </Link>
    </Typography>
  </Box>
);

export default Footer;
