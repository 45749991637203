import { Product } from '../models';

/**
 * @desc Combines the size keyword with its following number for a single search term
 * @param keywordArray Products search string split into an array
 * @returns {string[]} New search array with combined sizes i.e. 'size 5'
 */
export const concatSizeKeywords = (keywordArray: string[]): string[] => {
  return keywordArray
    .map((content, index, array) => {
      if (content === 'size') {
        const keyword = `${content} ${array[index + 1]}`;
        array.splice(index + 1, 1);
        return keyword;
      }
      return content;
    })
    .filter(Boolean);
};

/**
 * @desc Split the product's search_string into a keyword array.
 * @param  {Product[]} [data] Array of products
 * @returns {Product[]} Array of products with search array property
 * TODO: This should probably be done on the backend
 */
export const buildSearchArrayFromString = (data: Product[]): Product[] => {
  if (!data) return [];
  return data.map((product: Product) => {
    product.search_array = product.search_string.split(/[ ,]+/);
    product.search_array = concatSizeKeywords(product.search_array);
    return product;
  });
};

/**
 * @desc Format Date object into local date time string
 * @param  {Date} [date] date object
 * @returns {string} date in mm/dd/yyyy hh:mm format i.e. 10/30/2023 11:46 AM
 */
export const formatToLocalDateTime = (date: Date): string => {
  const utcDate = date.toLocaleDateString('en-US', { timeZone: 'UTC' });
  const localTime = date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
  return `${utcDate} ${localTime}`;
};
