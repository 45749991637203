import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { ReactComponent as MissingImg } from '../assets/missing-img.svg';

function ImageStepper({ images }: { images: string[] }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const Image = ({ alt, src }: { alt: string; src: string }) => {
    const [isError, setIsError] = useState(false);

    return !isError ? (
      <img
        style={{
          objectFit: 'contain',
          objectPosition: 'center',
          width: '100%',
          height: '100%',
        }}
        src={src}
        alt={alt}
        onError={() => {
          if (!isError) setIsError(true);
          return;
        }}
      />
    ) : (
      <MissingImg style={{ width: '100%', height: 200, fill: '#c6c6c6' }} />
    );
  };

  return (
    <Box
      sx={{
        maxWidth: '80vw',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          mb: 2,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <IconButton
          size="large"
          onClick={handleBack}
          disabled={activeStep === 0}
          sx={{ width: 64, height: 64 }}
        >
          <KeyboardArrowLeft fontSize="large" />
        </IconButton>
        <SwipeableViews
          style={{ margin: '0 1rem' }}
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={(step) => setActiveStep(step)}
          enableMouseEvents
        >
          {images.map((source, index) => (
            <div key={index}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Image alt={''} src={source} />
              ) : null}
            </div>
          ))}
        </SwipeableViews>
        <IconButton
          size="large"
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
          sx={{ width: 64, height: 64 }}
        >
          <KeyboardArrowRight fontSize="large" />
        </IconButton>
      </Box>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        // MobileStepper requires these props, but moved buttons above
        nextButton={<></>}
        backButton={<></>}
      />
    </Box>
  );
}

export default ImageStepper;
