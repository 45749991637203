import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, Stack, Typography } from '@mui/material';
import { GRID_GUTTER_SIZE } from '../theme';
import { Product } from '../models';
import { CellProps } from './ProductGrid';
import { useFirebaseContext } from '../context/Firebase.context';
import { logEvent } from 'firebase/analytics';
import { useProductGridContext } from '../context/ProductGrid.context';
import { ReactComponent as MissingImg } from '../assets/missing-img.svg';

interface ProductCellProps extends CellProps {
  products: Product[];
}

const Image = ({ alt, src }: { alt: string; src: string }) => {
  const [isError, setIsError] = useState(false);

  return !isError ? (
    <img
      style={{
        objectFit: 'contain',
        objectPosition: 'center',
        width: '100%',
        height: '100%',
      }}
      src={src}
      alt={alt}
      onError={() => {
        if (!isError) setIsError(true);
        return;
      }}
    />
  ) : (
    <MissingImg style={{ width: '100%', height: '100%', fill: '#c6c6c6' }} />
  );
};

const ProductCell = ({
  columnIndex,
  rowIndex,
  style,
  columnCount = 2,
  products,
}: ProductCellProps) => {
  const { analytics } = useFirebaseContext();
  const navigate = useNavigate();
  const { setGridRowScrollIndex } = useProductGridContext();
  const index = rowIndex * columnCount + columnIndex;

  if (index >= products.length) return null;

  const handleOnClick = (product: Product) => {
    setGridRowScrollIndex(rowIndex);
    navigate(`../product-detail/${product.id}`);
    if (!analytics) return;
    logEvent(analytics, 'view_item', {
      items: [
        {
          item_name: product.name,
          item_id: product.id,
          quantity: product.variants.length,
        },
      ],
    });
  };

  return (
    <div
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        left: (style.left as number) + GRID_GUTTER_SIZE,
        top: (style.top as number) + GRID_GUTTER_SIZE,
        width: (style.width as number) - GRID_GUTTER_SIZE,
        height: (style.height as number) - GRID_GUTTER_SIZE,
      }}
      onClick={() => handleOnClick(products[index])}
      className="productCell"
    >
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 1,
          height: '98%',
          width: '100%',
        }}
      >
        <Stack sx={{ alignItems: 'center' }} spacing={1}>
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              width: '100%',
              minHeight: 100,
              maxHeight: 100,
              justifyContent: 'center',
              alignItems: 'flex-end',
              overflow: 'hidden',
            }}
          >
            <Image
              alt={products[index].name}
              src={products[index].image_links[0]}
            />
          </Box>
          <Typography
            data-testid="product-name"
            gutterBottom
            variant="h6"
            sx={{
              m: 0,
              lineHeight: '1.1',
              fontWeight: 700,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'center',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {products[index].name}
          </Typography>
          <Typography data-testid="product-price">
            From {products[index].price_from}
          </Typography>
        </Stack>
      </Card>
    </div>
  );
};

export default ProductCell;
