import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  List,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';
import {
  ArrowBack,
  AttachMoney,
  CalendarToday,
  Category,
  CheckBox,
  CheckBoxOutlineBlank,
} from '@mui/icons-material';
import {
  FilterProps,
  useProductGridContext,
} from '../context/ProductGrid.context';
import FilterSection from './FilterSection';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export interface FilterFormProps {
  date: string;
  priceRange: { gte: string; lte: string };
  categories: string[];
}

const initialFormFilters: FilterFormProps = {
  date: '',
  priceRange: { gte: '', lte: '' },
  categories: [],
};

const FilterDrawer = ({ categories }: { categories: string[] }) => {
  const { filter, setFilter, isFilterDrawerOpen, setIsFilterDrawerOpen } =
    useProductGridContext();
  const [filterForm, setFilterForm] = useState(initialFormFilters);
  const isFilterApplied = Object.values(filter).some((filter: FilterProps) => {
    if (typeof filter === 'object')
      return Object.values(filter).some((value) => value);
    return filter;
  });

  const handleFilters = () => {
    Object.entries(filterForm).forEach(([key, value]) => {
      if (key === 'priceRange') {
        filter.priceRange = {
          gte: parseFloat(filterForm.priceRange.gte) * 100,
          lte: parseFloat(filterForm.priceRange.lte) * 100,
        };
      } else filter[key as keyof FilterProps] = value;
    });
    setFilter({ ...filter });
    setIsFilterDrawerOpen(false);
  };

  /**
   * In the event 'Clear All' filters button is clicked,
   * apply and close the filter drawer.
   */
  useEffect(() => {
    if (filterForm === initialFormFilters) handleFilters();
  }, [filterForm]);

  return (
    <Drawer
      open={isFilterDrawerOpen}
      anchor="right"
      onClose={() => setIsFilterDrawerOpen(false)}
    >
      <Paper sx={{ width: 300, boxShadow: 'none' }} elevation={0}>
        <Stack direction="row" spacing={1} sx={{ padding: '1rem 1rem 2rem 0' }}>
          <IconButton onClick={() => setIsFilterDrawerOpen(false)}>
            <ArrowBack />
          </IconButton>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <h3>Filters</h3>
          </Box>
          <Button
            onClick={() => setFilterForm(initialFormFilters)}
            disabled={!isFilterApplied}
          >
            Clear all
          </Button>
          <Button
            data-testid="apply-filter-button"
            variant="contained"
            onClick={handleFilters}
          >
            Apply
          </Button>
        </Stack>
        <List>
          <FilterSection
            title="Date"
            icon={<CalendarToday />}
            isActive={Boolean(filterForm.date)}
            clear={() => setFilterForm({ ...filterForm, date: '' })}
          >
            <FormControl>
              <RadioGroup
                name="date-radio-buttons-group"
                value={filterForm.date}
                onChange={(event) =>
                  setFilterForm({ ...filterForm, date: event.target.value })
                }
              >
                <FormControlLabel
                  data-testid="date-radio-new"
                  value="recently-created"
                  control={<Radio />}
                  label="Recently created"
                />
                <FormControlLabel
                  data-testid="date-radio-updated"
                  value="recently-updated"
                  control={<Radio />}
                  label="Recently updated"
                />
              </RadioGroup>
            </FormControl>
          </FilterSection>
          <FilterSection
            title="Price"
            icon={<AttachMoney />}
            isActive={Boolean(filterForm.priceRange.gte)}
            clear={() =>
              setFilterForm({ ...filterForm, priceRange: { gte: '', lte: '' } })
            }
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControl sx={{ m: 1 }} variant="standard">
                <TextField
                  size="small"
                  value={filterForm.priceRange.gte}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFilterForm(() => {
                      return {
                        ...filterForm,
                        priceRange: {
                          gte: event.target.value,
                          lte: filterForm.priceRange.lte,
                        },
                      };
                    });
                  }}
                  inputProps={{
                    type: 'number',
                    'data-testid': 'price-from',
                    placeholder: '10',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </FormControl>{' '}
              to{' '}
              <FormControl sx={{ m: 1 }} variant="standard">
                <TextField
                  size="small"
                  value={filterForm.priceRange.lte}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFilterForm(() => {
                      return {
                        ...filterForm,
                        priceRange: {
                          lte: event.target.value,
                          gte: filterForm.priceRange.gte || '0',
                        },
                      };
                    });
                  }}
                  inputProps={{
                    type: 'number',
                    'data-testid': 'price-to',
                    placeholder: '50',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Box>
          </FilterSection>
          <FilterSection
            title="Category"
            icon={<Category />}
            isActive={Boolean(filterForm.categories.length)}
            clear={() => setFilterForm({ ...filterForm, categories: [] })}
          >
            <Autocomplete
              data-testid="category-select"
              multiple
              value={filterForm.categories}
              options={categories}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              style={{ width: 250 }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select categories" />
              )}
              onChange={(
                event: SyntheticEvent<Element, Event>,
                value: string[]
              ) => setFilterForm({ ...filterForm, categories: value })}
            />
          </FilterSection>
        </List>
      </Paper>
    </Drawer>
  );
};

export default FilterDrawer;
