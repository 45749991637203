import TestLogo from './assets/test-logo.jpg';
import CKLogo from './assets/ck-logo.jpg';
// TODO: Switch logos when client is ready to update the background color
// import CGSLogo from './assets/cgs-logo.jpg';
import CGSLogo from './assets/cgs-logo-white-bg.jpg';

const environment = {
  ENVIRONMENT: process.env.NODE_ENV,
  COMMIT_HASH: process.env.REACT_APP_COMMIT_HASH,
  DATA_SYNC_INTERVAL: parseInt(
    process.env.REACT_APP_DATA_SYNC_INTERVAL || '1800000'
  ),
  ACCOUNT_DATA: {
    COOLKICKS: {
      BRANDING: {
        SITE_TITLE: 'CoolKicks - Store Catalog',
        FAVICON: '/favicons/favicon-ck.ico',
        LOGO: CKLogo,
      },
      ANALYTICS_CONFIG: {
        apiKey: 'AIzaSyCitLEPOwS6ho95XK5GL8iHPMKgbp-mv-w',
        authDomain: 'coolkicks-404620.firebaseapp.com',
        projectId: 'coolkicks-404620',
        storageBucket: 'coolkicks-404620.appspot.com',
        messagingSenderId: '758759995540',
        appId: '1:758759995540:web:da826ec5c9ed306a795c81',
        measurementId: 'G-4TCYMCX02G',
      },
      LOCATIONS: {
        COOLKICKS_LA: {
          STORAGE_URL: `https://digital-catalogs.thrivemetrics.com/236308/digital-catalog/2078532200835587895_adc5db72_v2/Digital_Catalog_Feed_(COOLKICKS_LA).json`,
        },
        THE_COOL: {
          STORAGE_URL: `https://digital-catalogs.thrivemetrics.com/236308/digital-catalog/2078531854654513149_1809a901_v2/Digital_Catalog_Feed_(THE_COOL).json`,
        },
        COOLKICKS_VEGAS: {
          STORAGE_URL: `https://digital-catalogs.thrivemetrics.com/236308/digital-catalog/2078531526173400930_b41812ee_v2/Digital_Catalog_Feed_(COOL_KICKS_VEGAS).json`,
        },
      },
    },

    CRISS_GOT_SOLES: {
      BRANDING: {
        SITE_TITLE: 'Criss Got Soles - Store Catalog',
        FAVICON: '/favicons/favicon-cgs.ico',
        LOGO: CGSLogo,
        THEME: 'CRISS_GOT_SOLES',
      },
      LOCATIONS: {
        CRISS_GOT_SOLES: {
          STORAGE_URL: `https://digital-catalogs.thrivemetrics.com/259988/digital-catalog/2145831099346046745_fd8dd25b_v2/Digital_Catalog_Feed_(crissgotsoles).json`,
        },
      },
    },

    TEST_ACCOUNT: {
      BRANDING: {
        SITE_TITLE: 'Test Account - Store Catalog',
        FAVICON: '',
        LOGO: TestLogo,
      },
      LOCATIONS: {
        TEST_LOCATION: {
          STORAGE_URL: `https://storage.googleapis.com/digital-catalogs-dev/236558/digital-catalog/2176234256882204950_e1a5ce42/Digital%20Catalog%20Feed%20(Broadway%20Store).json`,
        },
      },
    },
  },
};

export default environment;
