import React, { useEffect, useRef, useState } from 'react';
import { Await } from 'react-router-dom';
import { FixedSizeGrid } from 'react-window';
import { Button, Stack } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  FilterProps,
  useProductGridContext,
} from '../context/ProductGrid.context';
import useGridDimensions from '../hooks/useGridDimensions';
import { Product } from '../models';
import ProductCell from './ProductCell';
import NoData from './NoData';
import Search from './Search';
import SortMenu from './SortMenu';

export type CellProps = {
  columnIndex: number;
  rowIndex: number;
  style: React.CSSProperties;
  columnCount?: number;
};

const ProductGrid = () => {
  const { products, gridRowScrollIndex, filter, setIsFilterDrawerOpen } =
    useProductGridContext();
  const { rowHeight, columnWidth, columnCount } = useGridDimensions();
  const [gridHeight, setGridHeight] = useState<number>();
  const containerRef = useRef<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any
  const fixedSizeGridRef = useRef<FixedSizeGrid | null>(null);
  const isFilterApplied = Object.values(filter).some((filter: FilterProps) => {
    if (typeof filter === 'object')
      return Object.values(filter).some((value) => value);
    return filter;
  });

  useEffect(() => {
    function handleResize() {
      if (!containerRef?.current) return;
      setGridHeight(containerRef.current.offsetHeight);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [products]);

  useEffect(() => {
    if (gridRowScrollIndex)
      fixedSizeGridRef.current?.scrollToItem({
        align: 'start',
        rowIndex: gridRowScrollIndex,
      });
  }, []);

  return (
    <Await resolve={products}>
      {(products: Product[]) => (
        <>
          <Stack
            sx={{
              width: '60vw',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Search />
            <Stack direction="row" spacing={1} sx={{ pl: 2 }}>
              <SortMenu />
              <Button
                data-testid="filter-drawer-button"
                onClick={() => setIsFilterDrawerOpen(true)}
                startIcon={<FilterListIcon />}
                sx={{ color: isFilterApplied ? '#1976d2' : '#9797a5' }}
              >
                Filter
              </Button>
            </Stack>
          </Stack>
          <main
            ref={containerRef}
            style={{
              flexGrow: 1,
              width: '100%',
              overflow: 'hidden',
              margin: '0 1rem',
            }}
            data-testid="productGridContainer"
          >
            {!!products?.length && (
              <FixedSizeGrid
                ref={fixedSizeGridRef}
                columnCount={columnCount}
                columnWidth={columnWidth}
                height={gridHeight || 0}
                rowCount={Math.ceil(products.length / columnCount)}
                rowHeight={rowHeight}
                width={window.innerWidth}
                style={{ overflowX: 'hidden' }}
              >
                {(props: CellProps) =>
                  ProductCell({
                    ...props,
                    columnCount: columnCount,
                    products: products,
                  })
                }
              </FixedSizeGrid>
            )}
            {!products?.length && <NoData />}
          </main>
        </>
      )}
    </Await>
  );
};

export default ProductGrid;
