import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useProductGridContext } from '../context/ProductGrid.context';
import useDebounce from '../hooks/useDebouncedValue';
import { ReactComponent as MagnifyingGlassIcon } from '../assets/icons/magnifying-glass.svg';

const Search = () => {
  const { filter, setFilter } = useProductGridContext();
  const [search, setSearch] = useState(filter.search);
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    if (search !== filter.search) setFilter({ ...filter, search });
  }, [debouncedSearch, setSearch]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: 2,
      }}
    >
      <TextField
        hiddenLabel
        size="small"
        placeholder="Search"
        value={search}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearch(e.target.value)
        }
        inputProps={{ 'data-testid': 'searchInput' }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MagnifyingGlassIcon style={{ fill: 'gray' }} />
            </InputAdornment>
          ),
        }}
        sx={{
          width: '80%',
          maxWidth: '400px',
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '22px',
            borderColor: '#272727 !important',
            borderWidth: 'thin',
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
              borderRadius: '22px',
              borderColor: '#272727',
              borderWidth: 'thin',
            },
        }}
      />
    </Box>
  );
};

export default Search;
