import { GRID_GUTTER_SIZE } from '../theme';

export default function useGridDimensions() {
  const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
  /* Small devices such as large phones (640px and up) */
  /* Medium devices such as tablets (768px and up) */
  /* Large devices such as laptops (1024px and up) */
  /* Largest devices such as desktops (1280px and up) */

  interface MediaQueries {
    small: MediaQueryList;
    medium: MediaQueryList;
    large: MediaQueryList;
    largest: MediaQueryList;
  }

  const windowHeightBreakpoints: MediaQueries = {
    small: window.matchMedia('(max-height: 640px)'),
    medium: window.matchMedia('(min-height: 641px) and (max-height: 768px)'),
    large: window.matchMedia('(min-height: 769px) and (max-height: 1024px)'),
    largest: window.matchMedia('(min-height: 1025px)'),
  };
  const windowHeightBreakpoint =
    Object.keys(windowHeightBreakpoints).find(
      (key) => windowHeightBreakpoints[key as keyof MediaQueries].matches
    ) || windowHeightBreakpoints.small;

  const windowWidthBreakpoints = {
    small: window.matchMedia('(max-width: 640px)'),
    medium: window.matchMedia('(min-width: 641px) and (max-width: 768px)'),
    large: window.matchMedia('(min-width: 769px) and (max-width: 1024px)'),
    largest: window.matchMedia('(min-width: 1025px)'),
  };
  const windowWidthBreakpoint =
    Object.keys(windowWidthBreakpoints).find(
      (key) => windowWidthBreakpoints[key as keyof MediaQueries].matches
    ) || windowWidthBreakpoints.small;

  interface SizeOptions {
    small: number;
    medium: number;
    large: number;
    largest: number;
  }
  const columnsPerRowOptions: SizeOptions = {
    small: 2,
    medium: 4,
    large: 6,
    largest: 8,
  };
  const rowsVisibleOptions: SizeOptions = {
    small: 2,
    medium: 3,
    large: 4,
    largest: 6,
  };

  const rowHeight =
    windowHeight /
      rowsVisibleOptions[windowHeightBreakpoint as keyof SizeOptions] +
    GRID_GUTTER_SIZE;

  const columnCount =
    columnsPerRowOptions[windowWidthBreakpoint as keyof SizeOptions];

  const columnWidth = (windowWidth - (GRID_GUTTER_SIZE + 10)) / columnCount;

  return { rowHeight, columnWidth, columnCount };
}
