import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';

const ErrorMessage = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
      padding: 2,
    }}
  >
    <Alert severity="error" variant="outlined">
      <AlertTitle>
        Sorry, there was an issue loading the store catalog
      </AlertTitle>
      Please try again later
    </Alert>
  </Box>
);

export default ErrorMessage;
