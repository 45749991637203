import { useEffect } from 'react';
import { FirebaseOptions } from 'firebase/app';
import {
  Navigate,
  Outlet,
  RouteObject,
  createBrowserRouter,
  defer,
  useLoaderData,
} from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import environment from './environment';
import { customThemes } from './theme';
import { useFirebaseContext } from './context/Firebase.context';
import ProductGridContextProvider from './context/ProductGrid.context';
import { Product } from './models';
import ProductGrid from './components/ProductGrid';
import ErrorMessage from './components/ErrorMessage';
import Page404 from './components/Page404';
import Header from './components/Header';
import Footer from './components/Footer';
import ProductDetail from './components/ProductDetail';
import FilterDrawer from './components/FilterDrawer';
import {
  buildSearchArrayFromString,
  formatToLocalDateTime,
} from './utils/helpers';

const { ACCOUNT_DATA } = environment;
const hostname = window.location.hostname;

export const dataLoader = async (account: string, location: string) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const accountData = (ACCOUNT_DATA as any)[account];
    const { ANALYTICS_CONFIG } = accountData;
    const { LOGO, SITE_TITLE, FAVICON, THEME } = accountData.BRANDING;
    const { STORAGE_URL } = accountData.LOCATIONS[location];

    const response = await fetch(STORAGE_URL);
    if (!response.ok) {
      const { status, type, url } = response;
      throw new Error(JSON.stringify({ status, type, url }));
    }
    let data = await response.json();
    data = buildSearchArrayFromString(data);

    // Loop through all products to pull a list of available categories for filtering
    // TODO: This should probably be done on the backend
    const categories: string[] = [];
    data.forEach(({ category_names }: { category_names: string[] }) => {
      if (category_names)
        category_names.forEach((category) => {
          if (!categories.includes(category)) categories.push(category);
        });
    });
    categories.sort();

    const lastModified = response.headers.get('last-modified');
    if (lastModified)
      console.log(
        `Last synced data: ${formatToLocalDateTime(new Date(lastModified))}`
      );
    return defer({
      data,
      categories,
      ANALYTICS_CONFIG,
      LOGO,
      SITE_TITLE,
      FAVICON,
      THEME,
    });
  } catch (error: unknown) {
    console.log(error);
    throw new Error(error as string);
  }
};

interface LoaderDataProps {
  data: Product[];
  categories: string[];
  ANALYTICS_CONFIG: FirebaseOptions;
  LOGO: string;
  SITE_TITLE: string;
  FAVICON: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  THEME?: any;
}

const Wrapper = () => {
  const {
    data,
    categories,
    ANALYTICS_CONFIG,
    LOGO,
    SITE_TITLE,
    FAVICON,
    THEME,
  } = useLoaderData() as LoaderDataProps;
  const { setFirebaseConfig } = useFirebaseContext();

  useEffect(() => {
    if (ANALYTICS_CONFIG) setFirebaseConfig(ANALYTICS_CONFIG);
  }, []);

  // Set the title and favicon for the site based on url location
  const link: HTMLLinkElement | null =
    document.querySelector("link[rel~='icon']");
  if (link && FAVICON) link.href = FAVICON;
  if (SITE_TITLE) document.title = SITE_TITLE;

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <ThemeProvider theme={(customThemes as any)[THEME] || {}}>
      <CssBaseline />
      <Header logo={LOGO} />
      <ProductGridContextProvider data={data}>
        <Outlet />
        <FilterDrawer categories={categories} />
      </ProductGridContextProvider>
      <Footer />
    </ThemeProvider>
  );
};

const childRoutes = [
  { index: true, element: <Navigate to="./product-list" replace /> },
  {
    path: 'product-list',
    element: <ProductGrid />,
  },
  {
    path: 'product-detail/:productId',
    element: <ProductDetail />,
  },
];

const routes: { [keys: string]: RouteObject[] } = {
  'coolkicks.thrivemetrics.com': [
    {
      path: 'COOLKICKS_LA',
      loader: () => dataLoader('COOLKICKS', 'COOLKICKS_LA'),
      element: <Wrapper />,
      children: childRoutes,
      errorElement: <ErrorMessage />,
    },
    {
      path: 'THE_COOL',
      loader: () => dataLoader('COOLKICKS', 'THE_COOL'),
      element: <Wrapper />,
      children: childRoutes,
      errorElement: <ErrorMessage />,
    },
    {
      path: 'COOLKICKS_VEGAS',
      loader: () => dataLoader('COOLKICKS', 'COOLKICKS_VEGAS'),
      element: <Wrapper />,
      children: childRoutes,
      errorElement: <ErrorMessage />,
    },
  ],
  'crissgotsoles.thrivemetrics.com': [
    {
      path: 'CRISS_GOT_SOLES',
      loader: () => dataLoader('CRISS_GOT_SOLES', 'CRISS_GOT_SOLES'),
      element: <Wrapper />,
      children: childRoutes,
      errorElement: <ErrorMessage />,
    },
  ],
  'dcqa.thrivemetrics.com': [
    {
      path: 'TEST_LOCATION',
      loader: () => dataLoader('TEST_ACCOUNT', 'TEST_LOCATION'),
      element: <Wrapper />,
      children: childRoutes,
      errorElement: <ErrorMessage />,
    },
  ],
};

const router = createBrowserRouter([
  ...(hostname === 'localhost'
    ? Object.values(routes).flat(1) // Return all routes if in development
    : routes[hostname]),
  {
    path: '*',
    element: <Page404 />,
  },
]);
export default router;
