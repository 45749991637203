import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { Box, Button, Collapse, Divider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useProductGridContext } from '../context/ProductGrid.context';
import ImageStepper from './ImageStepper';
import VariantList from './VariantList';

const ProductDetail = () => {
  const navigate = useNavigate();
  const { products } = useProductGridContext();
  const { productId } = useParams();

  const product = products.find(({ id }) => id === productId);
  const isDescriptionHTML = /<\/?[a-z][\s\S]*>/i.test(
    product?.description || ''
  );
  const cleanDescription = DOMPurify.sanitize(product?.description || '');
  // Only show collapsed description view if it is longer than 256 characters
  const showCollapsedView =
    product?.description && product?.description.length > 256;
  const [expanded, setExpanded] = useState<boolean>(false);

  const description = isDescriptionHTML ? (
    <div dangerouslySetInnerHTML={{ __html: cleanDescription }} />
  ) : (
    <>{product?.description}</>
  );

  return (
    <Box sx={{ flexGrow: 1, width: '100%', px: 4 }}>
      <Button
        sx={{ color: '#b2b2b2', mb: 2 }}
        onClick={() => navigate(-1)}
        startIcon={<ArrowBackIcon />}
      >
        Back
      </Button>
      {!!product?.image_links.length && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <ImageStepper images={product.image_links} />
        </Box>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', px: 6 }}>
        <h2 style={{ marginBottom: 0 }}>{product?.name}</h2>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {cleanDescription && showCollapsedView ? (
            <>
              <Box
                sx={{
                  fontSize: 18,
                  '& > :not(style)': {
                    display: 'flex',
                    justifyContent: 'space-around',
                    maskImage: !expanded
                      ? 'linear-gradient(to bottom, black 50%, transparent 100%)'
                      : 'none',
                  },
                }}
              >
                <Collapse in={expanded} collapsedSize={80}>
                  {description}
                </Collapse>
              </Box>
              <Button onClick={() => setExpanded((prevValue) => !prevValue)}>
                Show {expanded ? 'less' : 'more'}
              </Button>
            </>
          ) : (
            description
          )}
        </Box>
        <Divider />
        {!!product?.variants.length && (
          <VariantList
            variants={product.variants}
            selectedProductName={product.name}
          />
        )}
      </Box>
    </Box>
  );
};

export default ProductDetail;
