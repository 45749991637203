import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactComponent as MagnifyingGlassIcon } from '../assets/icons/magnifying-glass.svg';

const NoData = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 3,
      backgroundColor: '#efefef',
      width: '100%',
      height: '100%'
    }}
  >
    <Box sx={{mb: 2}}>
      <MagnifyingGlassIcon style={{ fill: '#cccccc', fontSize: '5rem' }} />
    </Box>
    <Box>
      <Typography variant="h5" align="center">
        No products match your search
      </Typography>
      <Typography variant="body1" align="center">
        Try adjusting your search to see more products.
      </Typography>
    </Box>
  </Box>
);

export default NoData;
