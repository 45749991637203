import React, { useState } from 'react';
import Button from '@mui/material/Button';
import SortIcon from '@mui/icons-material/Sort';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useProductGridContext } from '../context/ProductGrid.context';

export interface SortState {
  sortBy: 'name' | 'price_from';
  sortOrder: 'desc' | 'asc';
}

const SortMenu = () => {
  const { setSortState } = useProductGridContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (sortState: SortState) => {
    setAnchorEl(null);
    if (!sortState) return;
    setSortState(sortState);
  };

  return (
    <>
      <Button
        id="sort-menu-button"
        data-testid="sort-menu-button"
        aria-controls={open ? 'sort-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={<SortIcon />}
        sx={{ color: '#9797a5' }}
      >
        Sort
      </Button>
      <Menu
        id="sort-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'sort-menu-button',
        }}
      >
        <MenuItem
          data-testid="sort-name-asc"
          onClick={() => handleClose({ sortBy: 'name', sortOrder: 'asc' })}
        >
          Name - ascending
        </MenuItem>
        <MenuItem
          data-testid="sort-name-desc"
          onClick={() => handleClose({ sortBy: 'name', sortOrder: 'desc' })}
        >
          Name - descending
        </MenuItem>
        <MenuItem
          data-testid="sort-price-asc"
          onClick={() =>
            handleClose({ sortBy: 'price_from', sortOrder: 'asc' })
          }
        >
          Price - low to high
        </MenuItem>
        <MenuItem
          data-testid="sort-price-desc"
          onClick={() =>
            handleClose({ sortBy: 'price_from', sortOrder: 'desc' })
          }
        >
          Price - high to low
        </MenuItem>
      </Menu>
    </>
  );
};

export default SortMenu;
