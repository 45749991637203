import { useEffect, useRef, useState } from 'react';
import { FixedSizeList } from 'react-window';
import { Variant } from '../models';
import React from 'react';
import { Box } from '@mui/material';
import VariantListRow from './VariantListRow';

interface RowProps {
  index: number;
  style: React.CSSProperties;
}

interface ListDimensions {
  height: number;
  width: number;
}

interface VariantListProps {
  variants: Variant[];
  selectedProductName: string;
}

const VariantList = ({ variants, selectedProductName }: VariantListProps) => {
  const [listDimensions, setListDimensions] = useState<ListDimensions>();
  const containerRef = useRef<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any

  useEffect(() => {
    setListDimensions({
      height: containerRef.current.clientHeight,
      width: containerRef.current.clientWidth,
    });
  }, []);

  return (
    <Box ref={containerRef} sx={{ height: '30vh', mt: 3 }}>
      {listDimensions && (
        <FixedSizeList
          height={listDimensions.height}
          itemCount={variants.length}
          itemSize={35}
          width={listDimensions.width}
        >
          {({ index, style }: RowProps) => (
            <Box sx={{ ...style, pt: 1, pr: 2 }}>
              <VariantListRow
                variant={variants[index]}
                selectedProductName={selectedProductName}
              />
            </Box>
          )}
        </FixedSizeList>
      )}
    </Box>
  );
};

export default VariantList;
