import Box from '@mui/material/Box';

interface HeaderProps {
  logo: string;
}

const Header = ({ logo }: HeaderProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 1,
    }}
  >
    <Box sx={{ margin: 2, width: '20vw', minWidth: 150, maxWidth: 200 }}>
      <img src={logo} alt="Location Logo" style={{ width: '100%' }} />
    </Box>
  </Box>
);

export default Header;
