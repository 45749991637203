import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface FilterSectionProps {
  title: string;
  icon: React.ReactNode;
  isActive: boolean;
  clear: () => void;
  children: JSX.Element | JSX.Element[];
}

const FilterSection = ({
  title,
  icon,
  isActive,
  clear,
  children,
}: FilterSectionProps) => {
  const [open, setOpen] = useState(false);

  return (
    <ListItem
      key={title}
      disablePadding
      sx={{ flexWrap: 'wrap', borderBottom: '1px solid #e0e0e0' }}
    >
      <ListItemButton
        data-testid={`${title}-section`}
        onClick={() => setOpen(!open)}
        selected={isActive}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
        {isActive && (
          <Button
            onClick={(event) => {
              event.stopPropagation();
              clear();
            }}
          >
            Clear
          </Button>
        )}
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem sx={{ pl: 4 }} component="div">
            {children}
          </ListItem>
        </List>
      </Collapse>
    </ListItem>
  );
};

export default FilterSection;
