import React, { createContext, useState, useContext, useEffect } from 'react';
import { Product } from '../models';
import { SortState } from '../components/SortMenu';
import { concatSizeKeywords } from '../utils/helpers';

export interface FilterProps {
  search: string;
  date: string;
  priceRange: { gte: number | undefined; lte: number | undefined };
  categories: string[];
}

const initialFilters: FilterProps = {
  search: '',
  date: '',
  priceRange: { gte: undefined, lte: undefined },
  categories: [],
};

interface IProdctGridContext {
  products: Product[];
  filteredProducts?: Product[];
  sortState?: SortState;
  setSortState: (value: SortState) => void;
  filter: FilterProps;
  setFilter: (value: FilterProps) => void;
  isFilterDrawerOpen: boolean;
  setIsFilterDrawerOpen: (value: boolean) => void;
  gridRowScrollIndex?: number;
  setGridRowScrollIndex: (value?: number) => void;
}

const ProductGridContext = createContext<IProdctGridContext>({
  products: [] as Product[],
  filteredProducts: [],
  sortState: undefined,
  setSortState: () => null,
  filter: initialFilters,
  setFilter: () => {},
  isFilterDrawerOpen: false,
  setIsFilterDrawerOpen: () => null,
  gridRowScrollIndex: undefined,
  setGridRowScrollIndex: () => null,
});

interface ProdctGridContextProviderProps {
  data: Product[];
  children: React.ReactNode | React.ReactNode[];
}

const isWithinLast30Days = (date: Date) => {
  if (date >= new Date()) return true;
  if (date >= new Date(new Date().setDate(new Date().getDate() - 30)))
    return true;
  return false;
};

const ProductGridContextProvider = ({
  data,
  children,
}: ProdctGridContextProviderProps) => {
  const allProducts = data;
  const [products, setProducts] = useState<Product[]>(allProducts || []);
  const [filter, setFilter] = useState<FilterProps>(initialFilters);
  const [sortState, setSortState] = useState<SortState | undefined>({
    sortBy: 'name',
    sortOrder: 'asc',
  });
  const [gridRowScrollIndex, setGridRowScrollIndex] = useState<
    number | undefined
  >();
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

  /**
   * Handles filtering out products based on search input.
   * Products are matched based on searchString (product & variant names).
   * */
  useEffect(() => {
    const filterProducts = (products: Product[]) => {
      return (
        products
          // Filter by date created or updated within the last 30 days
          .filter((product: Product) => {
            if (!filter.date) return true;
            if (filter.date === 'recently-created' && product.created) {
              return isWithinLast30Days(new Date(product.created));
            }
            if (filter.date === 'recently-updated' && product.updated_at) {
              return isWithinLast30Days(new Date(product.updated_at));
            }
            return false;
          })
          // Filter by price range
          .filter((product: Product) => {
            if (!filter.priceRange.gte) return true;
            return product.variants.some(
              ({ price_cents }) => price_cents >= filter.priceRange.gte!
            );
          })
          .filter((product: Product) => {
            if (!filter.priceRange.lte) return true;
            return product.variants.some(
              ({ price_cents }) => price_cents <= filter.priceRange.lte!
            );
          })
          // Filter by categories
          .filter((product: Product) => {
            if (!filter.categories.length) return true;
            if (!product.category_names?.length) return false;
            const categorySet = new Set(filter.categories);
            const commonItems = product.category_names.filter((category) =>
              categorySet.has(category)
            );
            return commonItems.length > 0;
          })
          // Filter by search input
          .filter(({ search_array }) => {
            let keywords = filter.search
              .trim()
              .toLocaleLowerCase()
              .split(/[ ,]+/);
            keywords = concatSizeKeywords(keywords);

            const matchedKeywords = keywords.filter((keyword) => {
              if (!search_array) return;
              return (
                search_array.findIndex((searchKey) => {
                  // If the keyword is 'size', find an exact match.
                  // Otherwise a partial match is fine.
                  const isSearchingForSize = /^size/.test(keyword);
                  if (isSearchingForSize) return searchKey === keyword;
                  return searchKey.includes(keyword);
                }) >= 0
              );
            });
            return matchedKeywords.length === keywords.length;
          })
      );
    };

    setProducts(sortProducts(filterProducts(allProducts)));
  }, [filter, sortState]);

  const sortProducts = (products: Product[]) => {
    if (!sortState) return products;
    const { sortBy, sortOrder } = sortState;

    // If sorting by price, we need to format the price_from string
    // to a float, then sort. Otherwise, we can sort by string value
    // using localeCompare
    if (sortBy === 'price_from') {
      return products.toSorted((a, b) => {
        const onlyNumericCharacters = /[^0-9.-]+/g;
        const aValue = Number(a.price_from?.replace(onlyNumericCharacters, ''));
        const bValue = Number(b.price_from?.replace(onlyNumericCharacters, ''));
        return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
      });
    } else {
      return products.toSorted((a, b) =>
        sortOrder === 'asc'
          ? ('' + a[sortBy]).localeCompare(b[sortBy]!)
          : ('' + b[sortBy]).localeCompare(a[sortBy]!)
      );
    }
  };

  return (
    <ProductGridContext.Provider
      value={{
        products,
        sortState,
        setSortState,
        filter,
        setFilter,
        isFilterDrawerOpen,
        setIsFilterDrawerOpen,
        gridRowScrollIndex,
        setGridRowScrollIndex,
      }}
    >
      {children}
    </ProductGridContext.Provider>
  );
};

export const useProductGridContext = () => useContext(ProductGridContext);

export default ProductGridContextProvider;
