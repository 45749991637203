import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import environment from './environment';
import router from './router';
import Loading from './components/Loading';
import './App.css';
import theme from './theme';
import { FirebaseContextProvider } from './context/Firebase.context';

/**
 * Fix for adjusting the true view height for mobile browsers. Known
 * issue that vh accounts for the mobile broswer's address bar as
 * it hides and show. This does not show in chrome devtools emulators.
 * More info here: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
const vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

const App = () => {
  const { COMMIT_HASH } = environment;
  console.log(`commit: ${COMMIT_HASH}`);

  useEffect(() => {
    window.addEventListener('resize', () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    return () => window.removeEventListener('resize', () => {});
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        className="catalog"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <FirebaseContextProvider>
          <RouterProvider router={router} fallbackElement={<Loading />} />
        </FirebaseContextProvider>
      </Box>
    </ThemeProvider>
  );
};

export default App;
