import React, {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import { Analytics, getAnalytics } from 'firebase/analytics';

interface IFirebaseContext {
  setFirebaseConfig: (options: FirebaseOptions) => void;
  analytics?: Analytics;
}

interface FirebaseContextProviderProps {
  children: ReactNode | ReactNode[];
}

const FirebaseContext = createContext<IFirebaseContext>({
  setFirebaseConfig: () => null,
  analytics: undefined,
});

const useFirebaseContext = () => useContext(FirebaseContext);

const FirebaseContextProvider = ({
  children,
}: FirebaseContextProviderProps) => {
  const [firebaseConfig, setFirebaseConfig] = useState<
    FirebaseOptions | undefined
  >();

  const analytics = useMemo(() => {
    if (!firebaseConfig) return;
    const firebaseApp = initializeApp(firebaseConfig);
    return getAnalytics(firebaseApp);
  }, [firebaseConfig]);

  return (
    <FirebaseContext.Provider
      value={{
        setFirebaseConfig,
        analytics,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export { useFirebaseContext, FirebaseContextProvider };
