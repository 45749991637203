import { Box } from '@mui/material';
import { Variant } from '../models';

interface VariantRowProps {
  variant: Variant;
  selectedProductName: string;
}

const VariantListRow = ({ variant, selectedProductName }: VariantRowProps) => (
  <Box
    sx={{
      display: 'flex',
      borderBottom: '1px solid #e1e1e1',
    }}
  >
    {/* 
      Use selectedProductName as a visual stopgap 
      in case a product doesn't have any variants 
    */}
    <Box sx={{ flexGrow: 1 }}>{variant.name || selectedProductName}</Box>
    <Box sx={{ textAlign: 'right' }}>{variant.price_display}</Box>
  </Box>
);

export default VariantListRow;
