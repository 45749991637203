import { createTheme } from '@mui/material/styles';

export const GRID_GUTTER_SIZE = 8;

const theme = createTheme({
  typography: {
    fontFamily: "'Lato', sans-serif",
    fontSize: 16,
    allVariants: {
      color: '#333333',
      lineHeight: 1.5,
    },
    h1: {
      fontSize: 32,
      fontWeight: 700,
    },
    h2: {
      fontSize: 29,
      fontWeight: 700,
    },
    h3: {
      fontSize: 26,
      fontWeight: 700,
    },
    h4: {
      fontSize: 23,
      fontWeight: 700,
    },
    h5: {
      fontSize: 20,
      fontWeight: 700,
    },
    h6: {
      fontSize: 18,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: 18,
    },
    subtitle2: {
      fontSize: 18,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
    button: {
      fontSize: 16,
      fontWeight: 700,
      textTransform: 'none',
    },
    caption: {
      fontSize: 13,
    },
    overline: {
      fontSize: 11,
    },
  },
  palette: {
    mode: 'light',
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          boxShadow: 'none',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: 'none',
          boxShadow: 'none',
        },
      },
    },
  },
});

/**
 * CUSTOM ACCOUNT THEMES
 */
export const customThemes = {
  CRISS_GOT_SOLES: createTheme({
    ...theme,
    // TODO: Uncomment when client has updated their product images with transparency
    // palette: {
    //   background: {
    //     default: '#c6c6c6',
    //     paper: '#c6c6c6',
    //   },
    // },
    // typography: {
    //   subtitle1: {
    //     color: '#808080 !important',
    //   },
    // },
  }),
};

export default theme;
