import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Page404 = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
      padding: 3,
      backgroundColor: '#efefef',
      width: '100%',
    }}
  >
    <Box>
      <Typography variant="h5" align="center">
        No location at this address
      </Typography>
      <Typography variant="body1" align="center">
        Try rescanning the QR code.
      </Typography>
    </Box>
  </Box>
);

export default Page404;
